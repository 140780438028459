#app {
    height: 100%;
    overflow: scroll;
}

#app::-webkit-scrollbar,
body::-webkit-scrollbar {
    display: none;
}

#app,
body {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
}

html, body, #app {
    overflow-x: hidden;
}

.pt-text-field:not(.invalid) {
    border-color: var(--grey-1) !important;
}

.portal-head-header {
    display: none !important;
}
